import React, { useEffect, useState } from 'react';
import './Loading.css';
import loading from '../../Media/loaders.svg';
import profileImage from '../../Media/profileImage.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

const Loading = () => {
    const [flightData, setFlightData] = useState(null);

    useEffect(() => {
        const storedFlightData = localStorage.getItem('requestData');
        if (storedFlightData) {
            setFlightData(JSON.parse(storedFlightData));
        }
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric', weekday: 'short' };
        const date = new Date(dateString).toLocaleDateString(undefined, options);
        return date;
    };

    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
          'phone_conversion_number': '(866) 410-4797'
        });
      
        // Initiate the phone call
        window.location.href = 'tel:+18664104797';
      };
      

    return (
        <>
            <div className="loading-container">
                {flightData && (
                    <div className="flight-info">
                        <h2>{`${flightData.from} - ${flightData.to}`}</h2>
                        <p>Departure: {formatDate(flightData.date)}</p>
                        {/* <p>Return: {formatDate(flightData.returnDate)}</p> */}
                        <p>Fares are per person, inclusive of all taxes and fees, but do not include baggage fees.</p>
                    </div>
                )}
                <h1 className="loading-text">Please Wait...</h1>
                <p>We are Searching Airfares For You From over 450+ Airlines</p>
                <div className="flight-animation">
                    <img src={loading} alt="loading image" />
                </div>

                {/* <div className="contact-number-container-loader">
                    <img src={profileImage} alt="Customer Support" className="profile-image" />
                    <span className="phone-number">1-718-540-6001</span>
                </div> */}

                <div>
                    <p><strong>Speak to Live Travel Experts for Assistance</strong></p>
                    <a href="tel:+18664104797" onClick={handlePhoneClick} style={{ cursor: 'pointer' }}className="contact-number-container-loader-loading">
                        <div className="image-container-call-loading">
                            <img src={profileImage} alt="Customer Support" className="profile-image-loading" />
                            <FontAwesomeIcon icon={faPhone} className="phone-icon-loading faa-ring animated" />
                        </div>
                        <span className="phone-number-loading">(866) 410-4797</span>
                    </a>
                </div>




            </div>

        </>
    );
};

export default Loading;
