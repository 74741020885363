import React from 'react';
import Booking from '../Bookings/Bookings';
import './LandingPage1.css';
import image from '../../Media/fap.jpeg';

// Import Font Awesome icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone } from '@fortawesome/free-solid-svg-icons';

import '@fortawesome/fontawesome-free/css/all.min.css';
import PopularDestinations from '../PopularDestinations/PopularDestinations';
import NumberPopup from '../NumberPopup/NumberPopup';
import Navbar1 from '../Navbar/Navbar1';

const handlePhoneClick = () => {
  // Google Ads conversion tracking for phone calls
  window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
    'phone_conversion_number': '(866) 410-4797'
  });

  // Initiate the phone call
  window.location.href = 'tel:+18664104797';
};



const LandingPage1 = () => {
  return (
    <>
      <div className="landing-page-1-container">
        <div className="landing-page-1-card">
          <h2 className="landing-page-1-title">Reservation Desk </h2>
          <h2 className="landing-page-1-title">24/7 Helpline ( Toll-free )</h2>
          <img
            src={image} 
            alt="Flight Attendant"
            className="landing-page-1-image"
          />
          <button  className="landing-page-1-cta-button"  href="tel:+18664104797" onClick={handlePhoneClick} >Booking, Changes & Cancellation</button>
          <div className="landing-page-1-call-info">
            <p>No Hold – Call Answered In 5 Sec</p>
            <a href="tel:+18664104797" className="landing-page-1-phone-number">
              <div className="landing-page-1-phone-box">
                {/* Animated Font Awesome Icon */}
                <FontAwesomeIcon
                  icon={faPhone}
                  className="landing-page-1-phone-icon fa-bounce" // Add bounce animation
                /> (866) 410-4797
              </div>
            </a>
            <p>24/7 Helpline</p>
          </div>
        </div>
      </div>
      <Booking/>

      <div className="sec-2-landing-page-1-container">
      <div className="sec-2-landing-page-1-card">
        <div className="sec-2-landing-page-1-top-circle">
          <div className="sec-2-landing-page-1-icon animate-icon">
            <i className="fas fa-headset"></i> {/* Customer Support Icon */}
          </div>
        </div>
        <h3 className="sec-2-landing-page-1-title">24-Hour Support</h3>
      </div>

      <div className="sec-2-landing-page-1-card">
        <div className="sec-2-landing-page-1-top-circle">
          <div className="sec-2-landing-page-1-icon animate-icon">
            <i className="fas fa-calendar-check"></i> {/* Booking Confirmation Icon */}
          </div>
        </div>
        <h3 className="sec-2-landing-page-1-title">Instant Booking</h3>
      </div>

      <div className="sec-2-landing-page-1-card">
        <div className="sec-2-landing-page-1-top-circle">
          <div className="sec-2-landing-page-1-icon animate-icon">
            <i className="fas fa-wallet"></i> {/* Flexible Payment Plans Icon */}
          </div>
        </div>
        <h3 className="sec-2-landing-page-1-title">Flexible Payment </h3>
      </div>

      <div className="sec-2-landing-page-1-card">
        <div className="sec-2-landing-page-1-top-circle">
          <div className="sec-2-landing-page-1-icon animate-icon">
            <i className="fas fa-user-tie"></i> {/* Expert Guidance Icon */}
          </div>
        </div>
        <h3 className="sec-2-landing-page-1-title">Expert Guidance </h3>
      </div>
    </div>
    <PopularDestinations/>
    <NumberPopup/>


    </>
  );
};

export default LandingPage1;
