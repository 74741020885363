import React, { useState } from "react";
import "./cookies.css";

const Cookies = () => {
  const [isVisible, setIsVisible] = useState(true);

  const handleAccept = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="ecperdoa-2-1-cookie-banner">
        <span className="ecperdoa-2-1-banner-text">
          This website uses cookies to ensure you get the best experience.{" "}
          <a href="/learn-more" className="ecperdoa-2-1-learn-more">
            Learn More
          </a>
        </span>
        <button onClick={handleAccept} className="ecperdoa-2-1-accept-btn">
          Accept
        </button>
      </div>
    )
  );
};

export default Cookies;
