import React from 'react';
import './NoFlightsFound.css'; // Assuming this is the correct path to your CSS file
import SearchResults from '../SearchResults/SearchResults';
import f12 from '../../Media/f12.png'
import { Link } from 'react-router-dom';


const NoFlightsFound = ({ from, to }) => {

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
      'phone_conversion_number': '(866) 410-4797'
    });
  
    // Initiate the phone call
    window.location.href = 'tel:+18664104797';
  };
  

  return (
    <div className="no-flights-found-container">
      <div className="gradient-background"></div>
      <div className="content">
        <img src={f12} alt="f12" />
        <h2>No Flights Found</h2>
        <p>Apologies, we couldn't find any flights at the moment. Please give us a call on <a href="tel:+18664104797" onClick={handlePhoneClick} style={{ cursor: 'pointer' }}> +1(866) 410-4797</a> to get the best flights and deals.</p>
        <Link to="/" className="btn btn-primary">Go back to Home</Link>

      </div>
    </div>
  );
};

export default NoFlightsFound;
