// AirlineReservationsSearch.js

import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlaneDeparture, faSyncAlt, faPlaneSlash, faDollarSign, faCalendarAlt, faUser, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import "./AirlineReservationsSearch.css";

const AirlineReservationsSearch = () => {
  const [showMessage, setShowMessage] = useState(false);

  const handleSearchClick = () => {
    setShowMessage(true);
  };

  const handlePhoneClick = () => {
    // Google Ads conversion tracking for phone calls
    window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
      'phone_conversion_number': '(866) 410-4797'
    });
  
    // Initiate the phone call
    window.location.href = 'tel:+18664104797';
  };

  return (
    <section className="Airline-Reservations-search-container">
      <div className="Airline-Reservations-search-menu">
        <div className="Airline-Reservations-search-menu-item">
          <FontAwesomeIcon icon={faPlaneDeparture} className="Airline-Reservations-search-menu-icon" />
          <span>NEW BOOKINGS</span>
        </div>
        <div className="Airline-Reservations-search-menu-item">
          <FontAwesomeIcon icon={faSyncAlt} className="Airline-Reservations-search-menu-icon" />
          <span>CHANGES</span>
        </div>
        <div className="Airline-Reservations-search-menu-item">
          <FontAwesomeIcon icon={faPlaneSlash} className="Airline-Reservations-search-menu-icon" />
          <span>CANCELLATIONS</span>
        </div>
        <div className="Airline-Reservations-search-menu-item">
          <FontAwesomeIcon icon={faDollarSign} className="Airline-Reservations-search-menu-icon" />
          <span>REFUNDS</span>
        </div>
      </div>
      <div className="Airline-Reservations-search-bar">
        <div className="Airline-Reservations-search-field">
          <span className="Airline-Reservations-search-label">FROM</span>
          <input type="text" placeholder="Your Origin" className="Airline-Reservations-search-input" />
        </div>
        <div className="Airline-Reservations-search-field">
          <span className="Airline-Reservations-search-label">TO</span>
          <input type="text" placeholder="Your Destination" className="Airline-Reservations-search-input" />
        </div>
        <div className="Airline-Reservations-search-field">
          <span className="Airline-Reservations-search-label">Roundtrip</span>
          <select className="Airline-Reservations-search-select">
            <option value="roundtrip">Roundtrip</option>
            <option value="oneway">One Way</option>
          </select>
        </div>
        <div className="Airline-Reservations-search-field">
          <span className="Airline-Reservations-search-label">11/15/2024 - 11/18/2024</span>
          <FontAwesomeIcon icon={faCalendarAlt} className="Airline-Reservations-search-calendar-icon" />
        </div>
        <div className="Airline-Reservations-search-field">
          <span className="Airline-Reservations-search-label">1 Adult, Economy</span>
          <FontAwesomeIcon icon={faUser} className="Airline-Reservations-search-user-icon" />
        </div>
        <button className="Airline-Reservations-search-button" onClick={handleSearchClick}>
          <FontAwesomeIcon icon={faPlaneDeparture} />
        </button>
      </div>
      
      {showMessage && (
        <div className="Airline-Reservations-search-message">
          <p>Our search services are currently offline. Please call us for the best deal:</p>
          <button className="Airline-Reservations-call-button" onClick={handlePhoneClick}>
            <FontAwesomeIcon icon={faPhoneAlt} className="Airline-Reservations-call-icon" />
            (866) 410-4797
          </button>
        </div>
      )}
    </section>
  );
};

export default AirlineReservationsSearch;
