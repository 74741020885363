import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../Navbar/Navbar.css';
import logo from '../../Media/Logo.png';
import profileImage from '../../Media/profileImage.jpg';

const LandingPageNavbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isSolid, setIsSolid] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleScroll = () => {
        if (window.scrollY > 50) {
            setIsSolid(true);
        } else {
            setIsSolid(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const navigate = useNavigate();


    const handlePhoneClick = () => {
        // Google Ads conversion tracking for phone calls
        window.gtag('config', 'AW-16610863777/ED5wCNmkn9QZEKHV1vA9', {
            'phone_conversion_number': '(866) 410-4797'
        });

        // Initiate the phone call
        window.location.href = 'tel:+18664104797';
    };





    return (
        <>

            <nav className={`navbar ${isSolid ? 'solid' : ''}`}>
                <a href="/" className="brand-name" onClick={closeMenu}>
                    <img src={logo} alt="Advance Techno" className="logo" />
                    <div className="brand-text">
                        <a><span className="techno">VGroup</span><br /><span className="techno">Ventures LLC</span></a>
                    </div>

                </a>



                <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
                    <li><a href="/" onClick={closeMenu}>Home</a></li>
                    <li><a href="/about" onClick={closeMenu}>About</a></li>
                    <li><a href="/services" onClick={closeMenu}>Services</a></li>
                    <li><a href="/contact_us" onClick={closeMenu}>Contact Us</a></li>
                </ul>



                <div className="parent-container">
                    <div className="landing-page-call-container">
                        <a href="tel:+18664104797" className="Navbar1-link">
                            <i className="fas fa-phone Navbar1-icon"></i>
                            <span className="Navbar1-text">Call Now: </span>
                            <span className="Navbar1-number" href="tel:+18664104797" onClick={handlePhoneClick}>(866) 410-4797</span>
                        </a>
                    </div>
                </div>




                {/* <button className="cta-button" onClick={handleClickCOntact}>Hire us!</button> */}
                <div className="hamburger-menu" onClick={toggleMenu}>
                    <div className="lines"></div>
                    <div className="lines"></div>
                    <div className="lines"></div>
                </div>
            </nav>





            <div className={`side-menu ${isOpen ? 'open' : ''}`}>
                <div className="close-button" onClick={closeMenu}>
                    &times;
                </div>
                <ul>
                    <li><a href="/" onClick={closeMenu}>Home</a></li>
                    <li><a href="/about" onClick={closeMenu}>About</a></li>
                    <li><a href="/services" onClick={closeMenu}>Services</a></li>

                    <li><a href="/contact_us" onClick={closeMenu}>Contact Us</a></li>
                </ul>
            </div>


        </>
    );
};

export default LandingPageNavbar;
