import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './PopularDestinations.css';
import london from '../../Media/London.webp';
import Miami from '../../Media/Miami.webp';
import Toronto from '../../Media/Toronto.webp';
import Florida from '../../Media/Florida.webp';
import Texas from '../../Media/Texas.webp';
import Dallas from '../../Media/Dallas.webp';

// Additional destination images (replace with actual image imports)
import AtlantaCabo from '../../Media/AtlantaCabo.webp';
import AtlantaCancun from '../../Media/AtlantaCancun.webp';

const destinations = [
    { name: 'London to Paris', from: 'LHR', to: 'CDG', image: london, price: '$340', rating: 5 },
    { name: 'Miami to Seville', from: 'MIA', to: 'SVQ', image: Miami, price: '$340', rating: 5 },
    { name: 'Toronto to Greece', from: 'YYZ', to: 'ATH', image: Toronto, price: '$340', rating: 5 },
    { name: 'Florida to Vienna', from: 'MCO', to: 'VIE', image: Florida, price: '$340', rating: 5 },
    { name: 'Texas to Madrid', from: 'DFW', to: 'MAD', image: Texas, price: '$340', rating: 5 },
    { name: 'Dallas to Italy', from: 'DFW', to: 'FCO', image: Dallas, price: '$340', rating: 5 },
    { name: 'Atlanta to Cabo San Lucas', from: 'ATL', to: 'SJD', image: AtlantaCabo, price: '$300', rating: 4 },
    { name: 'Atlanta to Cancun', from: 'ATL', to: 'CUN', image: AtlantaCancun, price: '$320', rating: 4 },
];

const PopularDestinations = () => {
    const [loading, setLoading] = useState(false);
    const [activeCard, setActiveCard] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleSubmit = async (fromCode, toCode, index) => {
        setLoading(true);
        setActiveCard(index);

        const getTomorrowsDate = () => {
            const today = new Date();
            const tomorrow = new Date(today);
            tomorrow.setDate(today.getDate() + 1);
            return tomorrow.toISOString().split('T')[0];
        };

        const searchData = {
            from: fromCode,
            to: toCode,
            date: getTomorrowsDate(),
            adult: 1,
            child: 0,
            infant: 0,
            currency: 'USD'
        };

        try {
            localStorage.setItem('requestData', JSON.stringify(searchData));
            console.log('Data saved to local storage:', searchData);
            await delay(500);
            navigate('/search_results', { state: { searchData: searchData } });
        } catch (error) {
            console.error("Error in handleSubmit: ", error);
        } finally {
            setLoading(false);
            setActiveCard(null);
        }
    };

    return (
        <div className="container">
            <div className="header">
                <h2>View Other Popular Tours</h2>
                <p>Discover routes for multi-stop international flights with VGroup Ventures LLC, our website designed to provide the most seamless way to traverse the globe.</p>
            </div>
            <div className={`destinations ${isMobile ? 'mobile' : ''}`}>
                {destinations.map((destination, index) => (
                    <div 
                        className={`destination-card ${loading && activeCard === index ? 'loading' : ''}`} 
                        key={index}
                        onClick={() => !loading && handleSubmit(destination.from, destination.to, index)}
                    >
                        {!isMobile && (
                            <LazyLoad height={150} offset={100}>
                                <img src={destination.image} alt={destination.name} />
                            </LazyLoad>
                        )}
                        <div className="destination-info">
                            <h3>{destination.name}</h3>
                            <div className="rating">{'★'.repeat(destination.rating)}</div>
                            <p>From <span>{destination.price}</span></p>
                        </div>
                        {loading && activeCard === index && <div className="loading-spinner">Loading...</div>}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PopularDestinations;
